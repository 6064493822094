import React from "react";
const Footer = () => {
  return (
    <div className="footer">
      <h6> all rights reserved © 2021</h6>
    </div>
  );
};

export default Footer;
